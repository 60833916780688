import { useTranslation } from 'react-i18next';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';

import { ButtonFormikTab, FormTextField } from '../../../../../components';
import { initialData } from '../../../../../constant/tourDetail';
import { InfoSection } from '../InfoSection';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux';
import { getStringDateFormat } from '../../../../../utils/getStringDate';
import { infoTagTypeConverter } from '../../../../../utils/infoTagTypeConverter';
import { updateTournamentInfo } from '../../../../../features/tournament/actionCreators';
import { InfoImageUpload } from '../InfoImageUpload';
import { selectTournament } from '../../../../../features/tournament/tournamentSelectors';
import { TagIdsEnum } from '../../../../../constant/navigation';
import { TournamentImage } from '../../../../../types/tournament';

import './InformationTag.scss';
import { Tooltip } from 'react-bootstrap';
import { OverlayTrigger } from 'react-bootstrap';
import { InfoCircleFill } from 'react-bootstrap-icons';

export type Section = {
    readonly id?: string | number;
    title: string;
    description: string | null;
};

export interface Values {
    name: string;
    description: string | null;
    startDate: string | null;
    endDate: string | null;
    lastCancelationDate: string | null;
    registrationOpen: string | null;
    registrationClose: string | null;
    registrationFee: number | null;
    sections: Array<Section>;
    img: null | string;
    icon: null | string;
    featureImg: null | string;
    images: Array<TournamentImage>;
    isFeatured: boolean | string;
    featureOrder: number;
}

export const InformationTag = () => {
    const { t } = useTranslation();
    const prefix = 'creativeDetail-';
    const inputPrefix = 'input-';
    const prefixValidation = 'validation-';
    const { communityId } = useParams<{ communityId: string }>();

    const TourDetailSchema = Yup.object().shape({
        name: Yup.string().max(40, t(prefixValidation + 'tooLong')),
        description: Yup.string().max(140, t(prefixValidation + 'tooLong')),
        startDate: Yup.date()
            .required(t(prefixValidation + 'required'))
            .typeError(t(prefixValidation + 'date')),
        endDate: Yup.date()
            .required(t(prefixValidation + 'required'))
            .typeError(t(prefixValidation + 'date'))
            .min(Yup.ref('startDate'), t(prefixValidation + 'endDateAfterStart')),
        lastCancelationDate: Yup.date()
            .required(t(prefixValidation + 'required'))
            .typeError(t(prefixValidation + 'date'))
            .max(Yup.ref('startDate'), t(prefixValidation + 'cancelBeforeStart')),
        registrationOpen: Yup.date()
            .required(t(prefixValidation + 'required'))
            .typeError(t(prefixValidation + 'date'))
            .max(Yup.ref('startDate'), t(prefixValidation + 'regOpenBeforeStart')),
        registrationClose: Yup.date()
            .required(t(prefixValidation + 'required'))
            .typeError(t(prefixValidation + 'date'))
            .max(Yup.ref('startDate'), t(prefixValidation + 'regCloseBeforeStart'))
            .min(Yup.ref('registrationOpen'), t(prefixValidation + 'regCloseAfterOpen')),
        registrationFee: Yup.number()
            .typeError(t(prefixValidation + 'mustBeANumber'))
            .required(t(prefixValidation + 'required'))
            .positive(t(prefixValidation + 'positive')),
        sections: Yup.array().of(
            Yup.object().shape({ title: Yup.string().required(t(prefixValidation + 'required')) })
        ),
    });

    const dispatch = useAppDispatch();
    const { data } = useAppSelector(selectTournament);

    const formikInitialState = data
        ? {
              name: data.name,
              description: data.description || '',
              startDate: getStringDateFormat(data.startDate),
              endDate: getStringDateFormat(data.endDate),
              lastCancelationDate: getStringDateFormat(data.lastCancelationDate),
              registrationOpen: getStringDateFormat(data.registrationOpen),
              registrationClose: getStringDateFormat(data.registrationClose),
              registrationFee: data.registrationFee || 0,
              sections: data.sections.map((section) =>
                  section.description === null ? { ...section, description: '' } : section
              ),
              img: data.img,
              icon: data.icon,
              featureImg: data.featureImg,
              images: data.images,
              isFeatured:
                  data.featureOrder !== null && data.featureOrder !== undefined ? true : false,
              featureOrder: data.featureOrder ?? 0,
          }
        : { ...initialData };

    return (
        <>
            <div className="information-tag px-2">
                <Formik
                    enableReinitialize={true}
                    validationSchema={TourDetailSchema}
                    initialValues={formikInitialState}
                    onSubmit={(values: Values) => {
                        data &&
                            dispatch(
                                updateTournamentInfo(
                                    data.id,
                                    +communityId,
                                    infoTagTypeConverter(values)
                                )
                            );
                    }}
                >
                    {({ values, setFieldValue }) => {
                        const images = values.images.filter((image) => image !== null);
                        const isFeatured =
                            // @ts-ignore - the toggle button saves it as a string
                            values.isFeatured === true || values.isFeatured === 'true';
                        return (
                            <Form autoComplete="off" id={TagIdsEnum.INFORMATION}>
                                <div className="my-3">
                                    <h4 className="font-weight-bold">{t(prefix + 'details')}</h4>
                                </div>
                                <div className="container-fluid px-0">
                                    <div className="row">
                                        <div className="col-lg-4 d-sm-block">
                                            <FormTextField
                                                type="text"
                                                placeholder={t(inputPrefix + 'placeholderName')}
                                                id="name"
                                                label={t(prefix + 'tournamentName')}
                                                name="name"
                                            />
                                            <FormTextField
                                                type="description"
                                                label={t(prefix + 'tournamentDescr')}
                                                name="description"
                                                id="description"
                                                placeholder={t(inputPrefix + 'placeholderDescr')}
                                            />
                                        </div>
                                        <div className="col">
                                            <div className="w-100 d-flex flex-wrap justify-content-between ">
                                                <div className="dateInputContainer pe-2 pe-lg-0">
                                                    <FormTextField
                                                        type="datetime-local"
                                                        id="startDate"
                                                        label={t(prefix + 'startDate')}
                                                        name="startDate"
                                                    />
                                                </div>
                                                <div className="dateInputContainer ps-2 ps-lg-0">
                                                    <FormTextField
                                                        type="datetime-local"
                                                        id="endDate"
                                                        label={t(prefix + 'endDate')}
                                                        name="endDate"
                                                    />
                                                </div>
                                                <div className="dateInputContainer pe-2 pe-lg-0">
                                                    <FormTextField
                                                        type="datetime-local"
                                                        id="lastCancelationDate"
                                                        label={t(prefix + 'cancelation')}
                                                        name="lastCancelationDate"
                                                    />
                                                </div>
                                                <div className="dateInputContainer ps-2 ps-lg-0">
                                                    <FormTextField
                                                        type="datetime-local"
                                                        id="registrationOpen"
                                                        label={t(prefix + 'regOpen')}
                                                        name="registrationOpen"
                                                    />
                                                </div>
                                                <div className="dateInputContainer pe-2 pe-lg-0">
                                                    <FormTextField
                                                        type="datetime-local"
                                                        id="registrationClose"
                                                        label={t(prefix + 'regClose')}
                                                        name="registrationClose"
                                                    />
                                                </div>
                                                <div className="dateInputContainer ps-2 ps-lg-0">
                                                    <FormTextField
                                                        placeholder="€0"
                                                        type="text"
                                                        id="registrationFee"
                                                        label={t(prefix + 'regFee')}
                                                        name="registrationFee"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="my-3">
                                    <h4 className="font-weight-bold">{t(prefix + 'image')}</h4>
                                </div>
                                <p className="w-100">{t(prefix + 'imageWarning')}</p>
                                <div className="d-flex flex-row flex-wrap gap-5 flex-lg-nowrap">
                                    <div>
                                        <h6 className="font-weight-bold text-center">
                                            {t(prefix + 'icon')}
                                        </h6>
                                        <p className="text-center">
                                            {t(prefix + 'iconRecommended')}
                                        </p>
                                        <InfoImageUpload
                                            setFieldValue={setFieldValue}
                                            imgUrl={values.icon}
                                            settingData={{
                                                width: '100',
                                                height: '100',
                                                name: 'icon',
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <h6 className="font-weight-bold text-center">
                                            {t(prefix + 'coverImage')}
                                        </h6>
                                        <div className="text-center">
                                            {t(prefix + 'coverImageRecommended')}
                                        </div>
                                        <div className="helperText">
                                            {t(prefix + 'coverImageHelperText')}
                                        </div>
                                        <InfoImageUpload
                                            setFieldValue={setFieldValue}
                                            imgUrl={values.img}
                                            settingData={{
                                                height: '353',
                                                width: '353',
                                                name: 'img',
                                            }}
                                            cropPreview={{
                                                topPercentage: 0,
                                                bottomPercentage: 30,
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <h6 className="font-weight-bold text-center">
                                            {t(prefix + 'featureImage')}
                                        </h6>
                                        {t(prefix + 'featureImgToggle')}
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip>{t(prefix + 'featureImgTooltip')}</Tooltip>
                                            }
                                        >
                                            <InfoCircleFill className="ml-2" />
                                        </OverlayTrigger>
                                        <div className="d-flex flex-row gap-2">
                                            <div className="flex-fill">
                                                <ButtonFormikTab
                                                    currValue={values.isFeatured}
                                                    items={[
                                                        { label: 'No', value: false },
                                                        { label: 'Yes', value: true },
                                                    ]}
                                                    name="isFeatured"
                                                />
                                            </div>
                                            <FormTextField
                                                className="py-0 flex-shrink-1"
                                                type="number"
                                                id="featureOrder"
                                                name="featureOrder"
                                                min={0}
                                                disabled={!isFeatured}
                                            />
                                        </div>
                                        <div className={isFeatured ? '' : 'opacity-50'}>
                                            <div className="text-center">
                                                {t(prefix + 'featureImageRecommended')}
                                            </div>
                                            <div className="helperText">
                                                {t(prefix + 'featureImageHelperText')}
                                            </div>
                                            <InfoImageUpload
                                                disabled={!isFeatured}
                                                setFieldValue={setFieldValue}
                                                imgUrl={values.featureImg}
                                                settingData={{
                                                    height: '270',
                                                    width: '360',
                                                    name: 'featureImg',
                                                }}
                                                cropPreview={{
                                                    topPercentage: 15,
                                                    bottomPercentage: 30,
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="overflow-scroll">
                                        <h6 className="font-weight-bold text-center">
                                            {t(prefix + 'additionalImages')}
                                        </h6>
                                        <p className="text-center">
                                            {t(prefix + 'coverImageRecommended')}
                                        </p>
                                        <div className="flex-nowrap d-flex flex-row gap-2">
                                            <FieldArray
                                                name="images"
                                                render={(arrayHelpers) => (
                                                    <>
                                                        {images.map((image, index) => {
                                                            return (
                                                                <div key={index}>
                                                                    <InfoImageUpload
                                                                        setFieldValue={(
                                                                            field: string,
                                                                            value: any,
                                                                            shouldValidate?: boolean
                                                                        ) => {
                                                                            setFieldValue(
                                                                                field,
                                                                                !!value
                                                                                    ? {
                                                                                          imageUrl:
                                                                                              value,
                                                                                          id: image.id,
                                                                                      }
                                                                                    : null,
                                                                                shouldValidate
                                                                            );
                                                                        }}
                                                                        clearImage={() => {
                                                                            setFieldValue(
                                                                                `images.${index}`,
                                                                                null
                                                                            );
                                                                        }}
                                                                        imgUrl={image.imageUrl}
                                                                        settingData={{
                                                                            height: '353',
                                                                            width: '353',
                                                                            name: `images.${index}`,
                                                                            type: 'img',
                                                                        }}
                                                                    />
                                                                </div>
                                                            );
                                                        })}
                                                        <button
                                                            type="button"
                                                            onClick={() => arrayHelpers.push('')}
                                                            className="rounded-2 border dashed-border flex-shrink-0"
                                                            style={{ width: '353px' }}
                                                        >
                                                            {t(prefix + 'addImage')}
                                                        </button>
                                                    </>
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="container-fluid px-0">
                                    <InfoSection
                                        data={values.sections}
                                        setFieldValue={setFieldValue}
                                    />
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </>
    );
};
